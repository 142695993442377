import React from "react"

import Heading from "../../shared/Heading"
import "./Hero.scss"
import Text from "../../shared/Text"
import ClientSection from "../ClientSection"
export default function Hero({
  btnText,
  img,
  text,
  header,
  id,
  scroll,
  social,
}) {
  return (
    <div className="hero-bg-about-us" id={id}>
      <div className="container position-relative">
        <div className="row pb-4">
          <div
            className="col-lg-6  hero-text-about"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-anchor-placement="top-center"
          >
            <div className="header-bg">
              <Heading variant="58" className="header">
                <h1> {header}</h1>
              </Heading>
            </div>
            <div className="pr-0 pr-sm-0 pr-md-3">
              <Text className="text">
                We're a team of engineers, designers, creators and managers on a
                mission of empowering digital transformations. We create
                innovative design-driven products, applications and solutions
                for companies that have the power to make life easier.
              </Text>
            </div>
          </div>
          <div className="col-md-6 p-md-4">
            <img
              src="/aboutUs/hero.svg"
              alt="about-us-hero"
              loading="lazy"
              className="img-fluid"
            />
          </div>
        </div>
        <ClientSection />
      </div>
    </div>
  )
}
